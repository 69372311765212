/* titles */
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;600&display=swap");
/* text */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,800;1,400&display=swap");
/* handwritten */
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

/* button */
@layer components {
	.btn {
		@apply btn-fcs;
		@apply btn-hvr;
		@apply btn-txt;
		@apply btn-cnt;
		@apply btn-frm;
		@apply cursor-pointer;
	}

	.btn-1 {
		@apply btn;
		@apply text-white;
		@apply bg-green-500 hover:bg-green-600;
		@apply focus:ring-green-600;
	}

	.btn-2 {
		@apply btn;
		@apply text-gray-900 dark:text-gray-100;
		@apply bg-white dark:bg-gray-800;
		@apply hover:bg-gray-300 dark:hover:bg-gray-900;
		@apply focus:ring-gray-900 dark:focus:ring-gray-100;
		@apply border-gray-200 dark:border-gray-800;
	}

	.btn-dis {
		@apply btn-txt;
		@apply btn-cnt;
		@apply btn-frm;
		@apply text-gray-500;
		@apply bg-white dark:bg-gray-800;
		@apply border-gray-200 dark:border-gray-800;
		@apply cursor-not-allowed;
	}

	.btn-scs {
		@apply bg-green-500 hover:bg-green-600;
	}

	.btn-dng {
		@apply btn;
		@apply text-white;
		@apply bg-red-600 hover:bg-red-800;
		@apply focus:ring-red-700;
	}

	.btn-lke {
		@apply btn-fcs;
		@apply btn-hvr;
		@apply text-gray-900 dark:text-gray-100;
		@apply btn-cnt;
		@apply bg-white dark:bg-gray-800;
		@apply hover:bg-gray-300 dark:hover:bg-gray-900;
		@apply focus:outline-none;
	}

	.btn-fcs {
		@apply focus:outline-none;
		@apply focus:ring-2;
		@apply focus:ring-offset-2;
		@apply focus:ring-offset-white dark:focus:ring-offset-black;
	}

	.btn-hvr {
	}

	.btn-txt {
		@apply font-medium text-base;
	}

	.btn-cnt {
		@apply flex items-center justify-center;
	}

	.btn-frm {
		@apply border border-transparent rounded-md;
	}

	.btn-sm {
		@apply text-xs;
		@apply px-2 py-1;
	}

	.btn-md {
		@apply text-sm;
		@apply px-4 py-2;
	}

	.btn-lg {
		@apply text-lg;
		@apply px-8 py-2;
	}

	.btn-xl {
		@apply text-xl;
		@apply px-12 py-4;
	}
}

/* link */
@layer components {
	.lnk {
		@apply flex items-center justify-center;
		@apply font-medium text-base;
		@apply border-b-2 border-transparent hover:border-current;
		@apply focus:outline-none focus:border-green-600;
	}

	.lnk-sm {
		@apply text-xs;
		@apply px-2 py-1;
	}

	.lnk-md {
		@apply text-sm;
		@apply px-4 py-2;
	}

	.lnk-lg {
		@apply text-lg;
		@apply px-8 py-4;
	}

	.lnk-xl {
		@apply text-xl;
		@apply px-12 py-4;
	}
}

/* input */
/* hide number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
@layer components {
	._input_hover {
	}

	._input_active {
	}

	._input_focus:focus {
		@apply outline-none;
		@apply ring ring-green-400;
	}

	._input_focus > input:focus {
		@apply outline-none;
	}

	._input_frame {
		@apply rounded-md;
		@apply min-w-0 w-full;
		@apply border border-gray-200 dark:border-gray-800;
	}

	._input_background {
		@apply bg-white dark:bg-gray-800;
	}

	._input_text {
		@apply text-ellipsis;
		@apply text-left;
		@apply text-gray-500 dark:text-gray-200;
	}

	._input_placeholder {
	}

	.input {
		@apply _input_hover;
		@apply _input_active;
		@apply _input_focus;
		@apply _input_frame;
		@apply _input_background;
		@apply _input_text;
		@apply _input_placeholder;
	}

	.input-group {
		@apply _input_frame;
		@apply _input_background;
	}

	.input-group > input {
		@apply focus:outline-none;
		@apply _input_text;
		@apply _input_placeholder;
	}

	.input-sm {
		@apply p-2;
		@apply text-xs;
	}

	.input-md {
		@apply p-2;
		@apply text-sm;
	}

	.input-lg {
		@apply p-2;
	}
}

/* checkout grids */
@layer components {
	.products-grid {
		@apply grid;
		grid-template-columns: max-content max-content max-content 1fr max-content max-content max-content max-content max-content;
		grid-template-rows: max-content max-content;
		@apply gap-x-4 md:gap-x-2;
		@apply gap-y-2 md:gap-y-0;
	}

	.order-grid-sm {
		@apply grid;
		@apply gap-y-2;
	}

	.order-grid-sm {
		grid-template-columns: max-content 1fr;
		@apply gap-x-4;
	}

	.order-grid-md {
		grid-template-columns: max-content max-content 1fr;
		@apply gap-x-8;
	}
}

@layer utilities {
	.bg {
		@apply bg-white dark:bg-black;
	}

	.pattern-moroccan {
		@apply pattern-moroccan-black dark:pattern-moroccan-white;
	}

	.pattern-moroccan-black {
		background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%23000000' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
	}

	.pattern-moroccan-white {
		background-image: url("data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%23FFFFFF' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
	}

	.pattern-aricma {
		background-image: url("https://images.unsplash.com/photo-1524275804141-5e9f2bc5a71d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80");
	}

	.text-1 {
		@apply text-gray-900;
		@apply dark:text-gray-100;
	}

	.text-2 {
		@apply text-gray-700;
		@apply dark:text-gray-300;
	}

	.text-3 {
		@apply text-gray-400;
		@apply dark:text-gray-500;
	}
}
